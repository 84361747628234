import { PluginTypes } from 'common/ContentEditor/plugins/pluginTypes';
import { ViewerPlugin } from 'wix-rich-content-common';
import type { ToolbarButtonProps } from 'wix-rich-content-common';
import { INSERT_PLUGIN_BUTTONS } from 'wix-rich-content-editor-common';

export enum PRESETS {
  VIEWER = 'viewer',
  EDITOR = 'editor',
}

export type EPluginButton =
  (typeof INSERT_PLUGIN_BUTTONS)[keyof typeof INSERT_PLUGIN_BUTTONS];

export type IToolbarButtons = {
  [key in EPluginButton]: ToolbarButtonProps;
};

export { INSERT_PLUGIN_BUTTONS };

export interface RichContentProps {
  preset?: PRESETS;
  className?: string;
  contentId?: 'custom_tab' | 'about_group' | string;
}

// do NOT import from ricos -> increase bundle size
export enum VerticalEmbedProviders {
  Event = 'event',
  Booking = 'booking',
  Product = 'product',
}

// do NOT import from ricos -> increase bundle size
export enum LinkPreviewProviders {
  Instagram = 'Instagram',
  Twitter = 'Twitter',
  YouTube = 'YouTube',
  TikTok = 'TikTok',
}

// do NOT import from ricos -> increase bundle size
export enum VideoProviders {
  Video = 'video',
  SoundCloud = 'soundCloud',
  YouTube = 'youTube',
}

export type PluginMap = {
  [key in PluginTypes]?: (config: any) => ViewerPlugin;
};
