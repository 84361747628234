import { useEnvironment } from '@wix/yoshi-flow-editor';
import React from 'react';

import {
  IconButton,
  IconButtonSkins,
  ActionsMenuLayout,
  ActionsMenuLayoutItemProps,
  Popover,
  MobileDrawer,
} from 'wix-ui-tpa';
import { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { EllipsesIcon } from '../Icons/EllipsesIcon';

import { st, classes } from './BlackAndWhiteThreeDots.st.css';

export interface ActionsDivider {
  isDivider: boolean;
}

export type ThreeDotsActions = ActionsMenuLayoutItemProps | ActionsDivider;

interface ThreeDotsProps extends TPAComponentProps {
  isOpen: boolean;
  items: ThreeDotsActions[];
  iconClassName?: string;

  onOpen(e?: any): void;
  onClose(): void;
}

const closeCodes = ['Escape', 'Tab'];

export const BlackAndWhiteThreeDots: React.FC<ThreeDotsProps> = (props) => {
  const { isOpen, onClose, items, onOpen, iconClassName, ...rest } = props;
  const { isMobile } = useEnvironment();

  React.useEffect(() => {
    if (typeof document === 'undefined') {
      return;
    }

    const listener = ({ code }: KeyboardEvent) => {
      if (closeCodes.includes(code)) {
        onClose();
      }
    };

    document.addEventListener('keydown', listener, true);

    return () => document.removeEventListener('keydown', listener, true);
  }, [isOpen, onClose]);

  if (isMobile) {
    return (
      <>
        <IconButton
          data-hook={props['data-hook']}
          onClick={handleClick}
          skin={IconButtonSkins.Full}
          className={st(classes.icon, {}, iconClassName)}
          icon={<EllipsesIcon width={24} height={24} />}
          aria-label="Actions menu"
        />
        <MobileDrawer isOpen={isOpen} onRequestClose={onClose}>
          <ActionsMenuLayout className={classes.actionsMenu}>
            {items.map((p) =>
              isDividerAction(p) ? (
                <ActionsMenuLayout.Divider />
              ) : (
                <ActionsMenuLayout.Item key={p.content} {...p} />
              ),
            )}
          </ActionsMenuLayout>
        </MobileDrawer>
      </>
    );
  }

  return (
    <Popover
      appendTo="parent"
      shown={isOpen}
      placement="bottom-end"
      onClickOutside={onClose}
      className={st(classes.root, {}, rest.className)}
    >
      <Popover.Element>
        <IconButton
          data-hook={props['data-hook']}
          onClick={handleClick}
          skin={IconButtonSkins.Full}
          className={st(classes.icon, {}, iconClassName)}
          icon={<EllipsesIcon width={24} height={24} />}
          aria-label="Actions menu"
        />
      </Popover.Element>
      <Popover.Content>
        <ActionsMenuLayout className={classes.actionsMenu}>
          {items.map((p) =>
            isDividerAction(p) ? (
              <ActionsMenuLayout.Divider />
            ) : (
              <ActionsMenuLayout.Item key={p.content} {...p} />
            ),
          )}
        </ActionsMenuLayout>
      </Popover.Content>
    </Popover>
  );

  function handleClick(event: React.MouseEvent) {
    event.stopPropagation();
    onOpen();
  }
};

BlackAndWhiteThreeDots.displayName = 'BlackAndWhiteThreeDots';

function isDividerAction(item: ThreeDotsActions): item is ActionsDivider {
  return item.hasOwnProperty('isDivider') && (item as ActionsDivider).isDivider;
}
