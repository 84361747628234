import { useMemo } from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';

type IFormattedParts = {
  [key in Intl.DateTimeFormatPartTypes]: any;
};

export function useDatePartsFormatter(options?: Intl.DateTimeFormatOptions) {
  const { language } = useEnvironment();

  const formatter = useMemo(
    () => new Intl.DateTimeFormat(language, options),
    [language],
  );

  return function (date: Date | number | undefined): IFormattedParts {
    if (typeof date === 'undefined') {
      return {} as IFormattedParts;
    }

    return formatter.formatToParts(new Date(date)).reduce(
      (acc, part) => ({
        ...acc,
        [part.type]: part.value,
      }),
      {} as IFormattedParts,
    );
  };
}
