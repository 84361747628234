import React from 'react';
import { TextField, TextFieldProps } from 'wix-ui-tpa';

import { classes, st } from './BlackAndWhiteTextField.st.css';

interface BlackAndWhiteTextFieldProps extends TextFieldProps {}

export const BlackAndWhiteTextField: React.FC<BlackAndWhiteTextFieldProps> = (
  props,
) => {
  return (
    <TextField {...props} className={st(classes.root, {}, props.className)} />
  );
};
