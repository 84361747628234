import { useMemo } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

interface IUserSearchPlaceholder {
  isSecret: boolean;
  count?: number;
}

export function useSearchPlaceHolder(props: IUserSearchPlaceholder) {
  const { count, isSecret } = props;

  const { t } = useTranslation();

  const placeholder = useMemo(() => {
    let key = '';
    if (isSecret) {
      key = 'groups-web.secret-group.add.members.search.placeholder_icu';
    } else {
      key = count
        ? 'groups-web.add.members.search.placeholder_icu'
        : 'groups-web.add.members.search.no-site-members.placeholder';
    }
    return t(key, { count });
  }, [count, isSecret]);

  return placeholder;
}
