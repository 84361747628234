import { invert } from 'lodash';
import type { ReactionType } from 'wix-ui-tpa';
import * as ReactionIcons from '@wix/wix-ui-icons-common/on-stage/reactions';

export const REACTION_CODE_MAP = {
  '❤️': 'heart',
  '😀': 'smiley',
  '😡': 'angry',
  '😮': 'surprised',
  '😢': 'sad',
  '😂': 'lol',
  '👍': 'thumbsup',
} as Record<string, ReactionType>;

export const REACTIONS_MAP = {
  thumbsup: {
    icon: ReactionIcons.ThumbUp,
    label: 'groups-web.feed.feed-item.reaction.thumbsup',
  },
  heart: {
    icon: ReactionIcons.Heart,
    label: 'groups-web.feed.feed-item.reaction.heart',
  },
  smiley: {
    icon: ReactionIcons.Smiley,
    label: 'groups-web.feed.feed-item.reaction.smiley',
  },
  lol: {
    icon: ReactionIcons.SmileyTears,
    label: 'groups-web.feed.feed-item.reaction.lol',
  },
  sad: {
    icon: ReactionIcons.Sad,
    label: 'groups-web.feed.feed-item.reaction.sad',
  },
  surprised: {
    icon: ReactionIcons.Surprised,
    label: 'groups-web.feed.feed-item.reaction.surprised',
  },
  angry: {
    icon: ReactionIcons.Angry,
    label: 'groups-web.feed.feed-item.reaction.angry',
  },
};

export const REACTION_TYPE_MAP = invert(REACTION_CODE_MAP) as Record<
  ReactionType,
  string
>;

export const REACTIONS = Object.keys(REACTIONS_MAP) as ReactionType[];

export const REACTION_CODES = Object.keys(REACTION_CODE_MAP) as string[];

export const ADDITIONAL_REACTIONS = REACTIONS.filter((id) => id !== 'thumbsup');
