import { useBi } from '@wix/yoshi-flow-editor';
import { joinRestrictedGroup } from '@wix/bi-logger-groups/v2';
import type { joinRestrictedGroupParams } from '@wix/bi-logger-groups/v2/types';
import { BIUserEntry } from 'common/bi-logger/types';

export function usePaidPlansBi() {
  const bi = useBi();

  const defaults: joinRestrictedGroupParams = {
    type: 'paid_plans',
    userEntry: BIUserEntry.SITE,
  };

  return {
    getPlanClick() {
      bi.report(
        joinRestrictedGroup({
          ...defaults,
          // origin: changeMembershipOrigin,
        }),
      );
    },
    getAnotherPlanClick() {
      bi.report(
        joinRestrictedGroup({
          ...defaults,
          origin: 'get_another_plan',
        }),
      );
    },
  };
}
