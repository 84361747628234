import { useMentions } from './useMentions';
import { usePolls } from './usePolls';
import { PluginTypes } from 'common/ContentEditor/plugins/pluginTypes';
import { useExperiments } from '@wix/yoshi-flow-editor';

interface IUseHelpersProps {
  groupId?: string;
}

export function useHelpers(props: IUseHelpersProps) {
  const polls = usePolls(props.groupId);
  const mentions = useMentions(props.groupId as string);

  const { experiments } = useExperiments();

  return {
    experiments,
    [PluginTypes.Mention]: mentions,
    [PluginTypes.Poll]: polls,
  };
}
