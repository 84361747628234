import React from 'react';
import { Input } from 'wix-ui-tpa';
import { SearchSmall, AddSmall } from '@wix/wix-ui-icons-common';

import { debounce } from 'lodash';
import { DATA_HOOKS } from './dataHooks';

import { st, classes } from './Search.st.css';

export interface SearchProps {
  value?: string;
  fullWidth?: boolean;
  forceOpen?: boolean;
  withCloseButton?: boolean;
  withBorder?: boolean;
  placeholder?: string;
  className?: string;
  onChange?(value: string): void;
}

interface SearchState {
  value: string;
  isExpanded: boolean;
}

export class Search extends React.Component<SearchProps, SearchState> {
  readonly ref = React.createRef<HTMLDivElement>();
  private readonly debouncedOnChange: any;

  constructor(props: SearchProps) {
    super(props);
    if (this.props.onChange) {
      this.debouncedOnChange = debounce(this.props.onChange, 500);
    }
    this.state = {
      value: props.value || '',
      isExpanded: this.props.forceOpen!,
    };
  }

  handleOnChange = (event: any) => {
    this.setValue(event.target.value);
  };

  handleOnBlur = () => {
    if (!this.state.value) {
      this.setIsExpanded(false);
    }
  };

  expand = () => {
    this.setIsExpanded(true);
  };

  collapse = () => {
    this.setIsExpanded(false);
    this.setValue('');
  };

  focusNativeInput = () => {
    if (this.ref.current) {
      this.ref.current.querySelector('input')?.focus();
    }
  };

  setIsExpanded = (newValue: boolean) => {
    const { forceOpen } = this.props;
    this.setState({ isExpanded: forceOpen || newValue });
  };

  setValue = (newValue: string) => {
    this.setState({ value: newValue });
    this.debouncedOnChange && this.debouncedOnChange(newValue);
  };

  render() {
    const { placeholder, withCloseButton, forceOpen, fullWidth, withBorder } =
      this.props;
    const { value, isExpanded } = this.state;
    return (
      <div
        ref={this.ref}
        className={st(
          classes.root,
          { isExpanded, fullWidth, withBorder } as any,
          this.props.className,
        )}
      >
        <Input
          className={classes.input}
          placeholder={placeholder}
          data-hook={DATA_HOOKS.searchInput}
          autoFocus={forceOpen ? false : isExpanded}
          onBlur={forceOpen ? () => {} : this.handleOnBlur}
          onFocus={this.expand}
          onChange={this.handleOnChange}
          value={value}
          prefix={
            <SearchSmall
              className={classes.searchIcon}
              width={24}
              height={24}
              onClick={this.expand}
            />
          }
          suffix={
            isExpanded &&
            withCloseButton &&
            value && (
              <button onClick={this.collapse} className={classes.closeButton}>
                <AddSmall
                  className={classes.closeIcon}
                  width={24}
                  height={24}
                />
              </button>
            )
          }
        />
      </div>
    );
  }
}
