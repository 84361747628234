import React from 'react';

export const LetterIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = (
  props,
) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g fillRule="nonzero">
      <path d="M12 0c6.628 0 12 5.372 12 12s-5.372 12-12 12S0 18.628 0 12 5.372 0 12 0zm0 .6C5.704.6.6 5.704.6 12S5.704 23.4 12 23.4 23.4 18.296 23.4 12 18.296.6 12 .6z" />
      <path d="M18 8v8H6V8h12zm-8.423 3.557L6.811 15.5h10.224l-2.693-3.841-2.344 1.954-2.421-2.056zm7.923-2.53l-2.773 2.311 2.773 3.953V9.027zm-11-.084v6.13l2.694-3.842L6.5 8.943zM17.351 8.5h-10.6l5.25 4.46 5.35-4.46z" />
    </g>
  </svg>
);
