import React from 'react';
import { Text, TextTypography } from 'wix-ui-tpa';
import cls from 'classnames';

import { Block, BlockFlow, BlockProps } from '../Block';
import { skeleton } from '../Skeleton';

import { st, classes } from './Card.st.css';

export interface CardTextProps {
  subtitle?: string | React.ReactNode;
  title?: React.ReactNode;
  dataHook?: string;
  className?: string;
  reverse?: boolean;
  ellipsis?: boolean;
  bw?: boolean;
  loading?: boolean;
}

export const CardText: React.FC<CardTextProps & BlockProps> = (props) => {
  const {
    subtitle,
    title,
    dataHook,
    reverse = false,
    ellipsis = false,
    bw = false,
    loading,
    ...rest
  } = props;

  return (
    <Block
      flow={BlockFlow.row}
      {...rest}
      className={st(classes.root, { reverse, ellipsis, bw }, rest.className)}
    >
      <Text
        typography={TextTypography.runningText}
        data-hook={dataHook}
        className={cls(classes.title, {
          [skeleton]: loading,
        })}
      >
        {title}
      </Text>

      {subtitle && (
        <Text
          typography={TextTypography.runningText}
          className={cls(classes.subtitle, {
            [skeleton]: loading,
          })}
        >
          {subtitle}
        </Text>
      )}
    </Block>
  );
};

CardText.displayName = 'CardText';
