import {
  IStyleParam,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';
import { doNotTransformSettingsKey, transformSettingsKey } from '../helpers';
import { FontTheme } from 'settings/consts';

export type IGroupStylesParams = {
  /**
   * Layout styles
   */
  coverImageHeight: IStyleParam<StyleParamType.Number>;
  layoutSpacing: IStyleParam<StyleParamType.Number>; // old one, now the value is split to hSpacing and vSpacing.
  hSpacing: IStyleParam<StyleParamType.Number>;
  vSpacing: IStyleParam<StyleParamType.Number>;
  infoColumnWidth: IStyleParam<StyleParamType.Number>;
  /**
   * Design styles
   */
  groupTitleColor: IStyleParam<StyleParamType.Color>;
  groupTitleFont: IStyleParam<StyleParamType.Font>;
  mobileGroupTitleFontSize: IStyleParam<StyleParamType.Number>;
  groupSubtitleColor: IStyleParam<StyleParamType.Color>;
  groupSubtitleFont: IStyleParam<StyleParamType.Font>;
  groupBreadcrumbsColor: IStyleParam<StyleParamType.Color>;
  groupBreadcrumbsFont: IStyleParam<StyleParamType.Font>;
  mobileGroupSubtitleFontSize: IStyleParam<StyleParamType.Number>;
  tabsColor: IStyleParam<StyleParamType.Color>;
  tabsFont: IStyleParam<StyleParamType.Font>;
  mobileTabsFontSize: IStyleParam<StyleParamType.Number>;
  secondaryButtonTextColor: IStyleParam<StyleParamType.Color>;
  secondaryButtonBackgroundColor: IStyleParam<StyleParamType.Color>;
  secondaryButtonBorderColor: IStyleParam<StyleParamType.Color>;
  secondaryButtonBorderWidth: IStyleParam<StyleParamType.Number>;
  buttonTextColor: IStyleParam<StyleParamType.Color>;
  buttonTextFont: IStyleParam<StyleParamType.Font>;
  buttonColor: IStyleParam<StyleParamType.Color>;
  buttonBorderColor: IStyleParam<StyleParamType.Color>;
  buttonBorderWidth: IStyleParam<StyleParamType.Number>;
  buttonBorderRadius: IStyleParam<StyleParamType.Number>;
  textButtonColor: IStyleParam<StyleParamType.Color>;
  textButtonFont: IStyleParam<StyleParamType.Font>;
  overlayColor: IStyleParam<StyleParamType.Color>;
  iconOverlayColor: IStyleParam<StyleParamType.Color>;
  groupHeaderIconsColor: IStyleParam<StyleParamType.Color>;
  appBackgroundColor: IStyleParam<StyleParamType.Color>;
  infoPanelsAboutTitleColor: IStyleParam<StyleParamType.Color>;
  infoPanelsAboutTitleFont: IStyleParam<StyleParamType.Font>;
  infoPanelsAboutSubtitleColor: IStyleParam<StyleParamType.Color>;
  infoPanelsAboutSubtitleFont: IStyleParam<StyleParamType.Font>;
  infoPanelsMembersTitleColor: IStyleParam<StyleParamType.Color>;
  infoPanelsMembersTitleFont: IStyleParam<StyleParamType.Font>;
  infoPanelsMembersSubtitleColor: IStyleParam<StyleParamType.Color>;
  infoPanelsMembersSubtitleFont: IStyleParam<StyleParamType.Font>;
  selectedTabWidth: IStyleParam<StyleParamType.Number>;
  selectedTabColor: IStyleParam<StyleParamType.Color>;
  dividerTabWidth: IStyleParam<StyleParamType.Number>;
  dividerTabColor: IStyleParam<StyleParamType.Color>;
};

export const groupStylesParams: IGroupStylesParams = {
  /**
   * Layout styles
   */
  coverImageHeight: {
    getDefaultValue: () => 240,
    key: 'coverImageHeight',
    type: StyleParamType.Number,
    inheritDesktop: false,
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  layoutSpacing: {
    getDefaultValue: () => 20,
    inheritDesktop: false,
    key: 'layoutSpacing',
    type: StyleParamType.Number,
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  hSpacing: {
    key: 'hSpacing',
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue }) => {
      return getStyleParamValue(groupStylesParams.layoutSpacing) || 20;
    },
  },
  vSpacing: {
    key: 'vSpacing',
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue }) => {
      return getStyleParamValue(groupStylesParams.layoutSpacing) || 20;
    },
  },
  infoColumnWidth: {
    key: 'infoColumnWidth',
    type: StyleParamType.Number,
    getDefaultValue: () => 35,
  },
  /**
   * Design styles
   */
  groupTitleColor: {
    key: 'groupTitleColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },

  groupTitleFont: {
    key: 'groupTitleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.HEADING_2, {
      size: 20,
    }),
  },
  mobileGroupTitleFontSize: {
    key: 'mobileGroupTitleFontSize',
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  },
  groupSubtitleColor: {
    key: 'groupSubtitleColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  groupSubtitleFont: {
    key: 'groupSubtitleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.PARAGRAPH_2, {
      size: 14,
    }),
  },
  groupBreadcrumbsColor: {
    key: 'groupBreadcrumbsColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  groupBreadcrumbsFont: {
    key: 'groupBreadcrumbsFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.PARAGRAPH_2, {
      size: 16,
    }),
  },
  mobileGroupSubtitleFontSize: {
    key: 'mobileGroupSubtitleFontSize',
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  },
  tabsColor: {
    key: 'tabsColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  tabsFont: {
    key: 'tabsFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.PARAGRAPH_2, {
      size: 16,
    }),
  },
  mobileTabsFontSize: {
    key: 'mobileTabsFontSize',
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  },
  secondaryButtonTextColor: {
    key: 'secondaryButtonTextColor',
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      const primaryBackgroundColor = props.getStyleParamValue(
        groupStylesParams.buttonColor,
      );

      return primaryBackgroundColor
        ? primaryBackgroundColor
        : wixColorParam('color-8')(props);
    },
  },
  secondaryButtonBackgroundColor: {
    key: 'secondaryButtonBackgroundColor',
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      return { value: 'rgba(255, 255, 255, 0)', name: null, opacity: 0 };
    },
  },
  secondaryButtonBorderColor: {
    key: 'secondaryButtonBorderColor',
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      const primaryBackgroundColorValue = props.getStyleParamValue(
        groupStylesParams.buttonColor,
      );

      return primaryBackgroundColorValue
        ? primaryBackgroundColorValue
        : groupStylesParams.buttonBorderColor.getDefaultValue?.(props);
    },
  },
  secondaryButtonBorderWidth: {
    key: 'secondaryButtonBorderWidth',
    type: StyleParamType.Number,
    getDefaultValue: (props) => {
      const primaryButtonBorderWidth = props.getStyleParamValue(
        groupStylesParams.buttonBorderWidth,
      );
      return primaryButtonBorderWidth ? primaryButtonBorderWidth : 1;
    },
  },
  buttonTextColor: {
    key: 'buttonTextColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonTextFont: {
    key: 'buttonTextFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.PARAGRAPH_2, {
      size: 16,
    }),
  },
  buttonColor: {
    key: 'buttonColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBorderColor: {
    key: 'buttonBorderColor',
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      const backgroundColorValue = props.getStyleParamValue(
        groupStylesParams.buttonColor,
      );

      return backgroundColorValue
        ? backgroundColorValue
        : groupStylesParams.buttonColor.getDefaultValue?.(props);
    },
  },
  buttonBorderWidth: {
    key: 'buttonBorderWidth',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonBorderRadius: {
    key: 'buttonBorderRadius',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  textButtonColor: {
    key: 'textButtonColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  textButtonFont: {
    key: 'textButtonFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.PARAGRAPH_2, {
      size: 16,
    }),
  },
  overlayColor: {
    key: 'overlayColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.6),
  },
  iconOverlayColor: {
    key: 'iconOverlayColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  groupHeaderIconsColor: {
    key: 'groupHeaderIconsColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  appBackgroundColor: {
    key: 'appBackgroundColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },

  infoPanelsAboutTitleColor: {
    key: 'infoPanelsAboutTitleColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  infoPanelsAboutTitleFont: {
    key: 'infoPanelsAboutTitleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.PARAGRAPH_2, {
      size: 16,
    }),
  },
  infoPanelsAboutSubtitleColor: {
    key: 'infoPanelsAboutSubtitleColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  infoPanelsAboutSubtitleFont: {
    key: 'infoPanelsAboutSubtitleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.PARAGRAPH_2, {
      size: 16,
    }),
  },
  infoPanelsMembersTitleColor: {
    key: 'infoPanelsMembersTitleColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  infoPanelsMembersTitleFont: {
    key: 'infoPanelsMembersTitleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.PARAGRAPH_2, {
      size: 16,
    }),
  },
  infoPanelsMembersSubtitleColor: {
    key: 'infoPanelsMembersSubtitleColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  infoPanelsMembersSubtitleFont: {
    key: 'infoPanelsMembersSubtitleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.PARAGRAPH_2, {
      size: 16,
    }),
  },
  selectedTabWidth: {
    key: 'selectedTabWidth',
    type: StyleParamType.Number,
    getDefaultValue: () => 3,
  },
  selectedTabColor: {
    key: 'selectedTabColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  dividerTabWidth: {
    key: 'dividerTabWidth',
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  dividerTabColor: {
    key: 'dividerTabColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
};
