import React from 'react';

import { useTranslation } from '@wix/yoshi-flow-editor';
import { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { EmptyState } from 'common/components/EmptyState';

import { st, classes } from './InviteMembersCard.st.css';

interface InviteMembersCardProps extends TPAComponentProps {
  onClick(): void;
}

export const InviteMembersCard: React.FC<InviteMembersCardProps> = ({
  onClick,
  className,
}) => {
  const { t } = useTranslation();

  const [isClosed, setIsClosed] = React.useState(false);

  if (isClosed) {
    return null;
  }

  return (
    <EmptyState
      className={st(classes.root, {}, className)}
      title={t('groups-web.invite-members-card.title')}
      content={t('groups-web.invite-members-card.subtitle')}
      button={{
        label: t('groups-web.invite-members-card.cta'),
        onClick,
      }}
      boxProps={{
        onClose: handleCloseClick,
      }}
    />
  );

  function handleCloseClick() {
    setIsClosed(true);
  }
};
