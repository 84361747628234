import React from 'react';
import { useTranslation, Trans } from '@wix/yoshi-flow-editor';

import { EmptyState, EmptyStateProps } from 'common/components/EmptyState';

interface IErrorStateProps
  extends Omit<EmptyStateProps, 'title' | 'content' | 'button'> {
  onRetry?(): void;
}

export function ErrorState(props: IErrorStateProps) {
  const { onRetry, ...rest } = props;
  const { t } = useTranslation();

  return (
    <EmptyState
      {...rest}
      title={t('groups-web.discussion.error-state.title')}
      content={
        <Trans
          i18nKey={t('groups-web.discussion.error-state.owner.message')}
          components={[
            <span key="0">Try reloading</span>,
            <br key="1" />,
            <span key="2">contact</span>,
          ]}
        />
      }
      button={
        !props.onRetry
          ? undefined
          : {
              label: t('groups-web.discussion.error-state.cta'),
              onClick: handleClick,
            }
      }
    />
  );

  function handleClick() {
    props.onRetry?.();
  }
}
