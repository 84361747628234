import React from 'react';

import { IconButton } from 'wix-ui-tpa';
import { CloseSmall as CloseSmallIcon } from '@wix/wix-ui-icons-common/on-stage';

import { useShadowStyles } from 'common/hooks/useShadowStyles';

import { st, classes } from './Box.st.css';

export interface BoxProps {
  article?: boolean;
  withTopBottomBorders?: boolean;
  withSideBorders?: boolean;
  onClose?(): void;

  ignoreShadow?: boolean;
}

type Props = BoxProps & JSX.IntrinsicElements['article'];

/**
 * Box
 */
export const Box = React.forwardRef<HTMLElement, Props>(
  (
    {
      article,
      children,
      withSideBorders,
      withTopBottomBorders,
      onClose,
      ignoreShadow,
      ...props
    },
    ref,
  ) => {
    const shadowStyles = useShadowStyles();

    return (
      <article
        {...props}
        className={st(
          classes.root,
          {
            article,
            withSideBorders,
            withTopBottomBorders,
            showShadow: shadowStyles.show,
            ignoreShadow,
          } as any,
          props.className,
        )}
        ref={ref}
      >
        {onClose ? (
          <IconButton
            className={classes.closeIcon}
            icon={<CloseSmallIcon />}
            onClick={onClose}
          />
        ) : null}
        {children}
      </article>
    );
  },
);

Box.defaultProps = {
  withSideBorders: true,
  withTopBottomBorders: true,
};
Box.displayName = 'Box';
