import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Collapse } from 'react-collapse';
import {
  useEnvironment,
  useExperiments,
  ErrorMonitorBoundary,
} from '@wix/yoshi-flow-editor';

import type { PluginConfigSyncType } from '@wix/comments-ooi-client';
import { WixComments } from '@wix/comments-ooi-client';
import { WixCommentsTranslationsProvider } from '@wix/comments-ooi-translations-provider';
// eslint-disable-next-line import/no-extraneous-dependencies
import { UserAction } from '@wix/ambassador-comments-v1-comment/types';

import type { IFeedItem } from 'store/feed/types';
import { selectGroupSlug } from 'store/groups/selectors';

import { useCurrentStateAndParams, useRouter } from 'common/router';
import { useRelativeTimeFormatter } from 'common/hooks';
import { PluginTypes } from 'common/ContentEditor/plugins/pluginTypes';
import { useMentions } from 'Group/Widget/RichContent/hooks/useMentions';

import { CommentsError } from './CommentsError';
import { pluginConfig } from './plugins';

import { classes, st } from './Comments.st.css';

interface ICommentsProps {
  item: IFeedItem;
  isJoined: boolean;
  className?: string;
  collapsed?: boolean;
}

export const Comments: React.FC<ICommentsProps> = (props) => {
  const { isMobile } = useEnvironment();
  const { className, item, isJoined } = props;
  const mentions = useMentions(item.applicationContext.groupId!, true);
  const { experiments } = useExperiments();
  const rtf = useRelativeTimeFormatter();

  const router = useRouter();
  const { params } = useCurrentStateAndParams();
  const slug = useSelector(selectGroupSlug(item.applicationContext.group));

  const plugins = useMemo(() => {
    const config = pluginConfig(experiments, isMobile);
    return {
      ...config,
      [PluginTypes.Mention]: {
        ...config[PluginTypes.Mention],
        config: mentions,
      },
    };
  }, []);

  return (
    <ErrorMonitorBoundary fallback={<CommentsError />}>
      <WixCommentsTranslationsProvider>
        <div className={st(classes.root, {}, className)}>
          <Collapse
            isOpened={!props.collapsed}
            theme={{ collapse: classes.collapse }}
          >
            <WixComments
              hideHeader={true}
              blackAndWhiteModals
              hideZeroCommentsEmptyState
              isLocked={false}
              _useInlineMobile={false}
              disableResourceCleanup
              disableResourceAutoFetching
              disableWriteBeforeLogin={false}
              pluginConfig={plugins as unknown as PluginConfigSyncType}
              resourceId={item.feedItemId as string}
              formatRelativeTime={rtf}
              isPreconditionRequiredForAction={guardCommentsAction}
              deepLink={{
                commentId: params.commentId,
                generateLink: handleGenerateLink,
              }}
              ctxFields={{
                claims: ['wix.feed.FeedAccess'],
                contextId: item.applicationContext.groupId as string,
                contextType: 'postId',
              }}
              pagination={{
                replyShowMoreLimit: 10,
                initialPage: {
                  commentLimit: 1,
                  replyLimit: 0,
                  offset: 0,
                },
                pagination: {
                  commentLimit: 20,
                  replyLimit: 0,
                },
              }}
            />
          </Collapse>
        </div>
      </WixCommentsTranslationsProvider>
    </ErrorMonitorBoundary>
  );

  function guardCommentsAction(action: UserAction): boolean {
    switch (action) {
      case UserAction.VIEW:
        return false;
      default:
        return !isJoined;
    }
  }

  function handleGenerateLink(commentId: string) {
    return router.href(
      'group.discussion.post',
      {
        slug,
        commentId,
        feedItemId: item.feedItemId,
      },
      {
        absolute: true,
      },
    );
  }
};
