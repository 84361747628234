import React from 'react';

export const GiphyPluginIcon: React.FC<any> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={13}
    viewBox="0 0 19 13"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="currentColor">
      <path d="M10 1v11.2M14.51 12.227V1.405h4.502M14.154 5.407h3.85M6 3.089a2.08 2.08 0 0 0-2.041-1.684h-.875a2.59 2.59 0 0 0-2.59 2.59v5.068a2.753 2.753 0 0 0 2.753 2.753h.346A2.407 2.407 0 0 0 6 9.409V8a1 1 0 0 0-1-1H2.804" />
    </g>
  </svg>
);
