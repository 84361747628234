import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';

import { Text, TextTypography, Dialog, DialogProps } from 'wix-ui-tpa';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { useScrollBlock } from '../../hooks';

import { st, classes } from './Modal.st.css';

type IModal<P> = React.FC<P> & {
  Buttons: React.FC;
  Header: React.FC<ModalHeaderProps>;
};

export enum ModalSkin {
  REACTIONS = 'reactions',
  NEW_POST = 'newPost',
}

interface ModalHeaderProps {
  title: string;
  subtitle?: string;
}

export interface ModalProps extends DialogProps {
  className?: string;
  skin?: ModalSkin;
}

export const Modal: IModal<ModalProps> = (props) => {
  const { skin, isOpen, ...rest } = props;
  const { isMobile } = useEnvironment();

  useScrollBlock(isOpen);

  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      timeout={300}
      in={isOpen}
      classNames={classes as CSSTransitionClassNames}
    >
      <Dialog
        {...rest}
        isOpen
        forceBWTheme
        wiredToSiteColors={false}
        className={classes.root}
        childrenWrapperClassName={classes.dialogContent}
        contentClassName={st(
          classes.contentWrapper,
          { skin: skin!, mobile: isMobile },
          rest.className,
        )}
      />
    </CSSTransition>
  );
};

Modal.displayName = 'Modal';
Modal.Buttons = (props) => (
  <div className={classes.buttons}>{props.children}</div>
);

Modal.Header = ({ title, subtitle }) => (
  <div className={classes.header}>
    <Text
      typography={TextTypography.smallTitle}
      className={classes.title}
      tagName="h1"
    >
      {title}
    </Text>
    {subtitle ? (
      <Text
        typography={TextTypography.runningText}
        className={classes.subtitle}
        tagName="h2"
      >
        {subtitle}
      </Text>
    ) : null}
  </div>
);
