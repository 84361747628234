import { groupStylesParams, IGroupStylesParams } from 'settings/group';
import { IFeedStylesParams, feedStylesParams } from 'settings/feed';
import { ICommentsStylesParams, commentsStylesParams } from 'settings/comments';
import {
  IAppearanceStylesParams,
  appearanceStylesParams,
} from 'settings/appearance';

export type IStylesParams = IGroupStylesParams &
  IAppearanceStylesParams &
  IFeedStylesParams &
  ICommentsStylesParams;

const stylesParams: IStylesParams = {
  ...appearanceStylesParams,
  ...groupStylesParams,
  ...feedStylesParams,
  ...commentsStylesParams,
};

export default stylesParams;
