import { useMemo } from 'react';

import { useEnvironment, createRtfFormatter } from '@wix/yoshi-flow-editor';

function dateDiffInDays(a: Date, b: Date): number {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;

  return Math.abs(a.getTime() - b.getTime()) / MS_PER_DAY;
}

export function useRelativeTimeFormatter() {
  const { language } = useEnvironment();

  return useMemo(() => {
    // createRtfFormatter from @wix/yoshi-flow-editor support days as max unit.
    // "666 days ago" looks strange.
    // So we try to print localized date if days ago > 31.
    return function (time: number | string | Date): string {
      const daysDiff = dateDiffInDays(new Date(time), new Date());
      const isExceeded = daysDiff > 31;

      if (isExceeded && !!Intl.DateTimeFormat) {
        const rtf = new Intl.DateTimeFormat(language, {
          dateStyle: 'long',
        });

        return rtf.format(new Date(time));
      }

      return createRtfFormatter(
        language,
        { floorSeconds: true, extraShortExpression: false },
        {
          numeric: 'auto',
          localeMatcher: 'lookup',
          style: 'long',
        },
      )(time);
    };
  }, [language]);
}
